import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'
import { defineComponent, ref, computed, watch,reactive } from 'vue'
const router = createRouter({
  history: createWebHistory('/'),
  routes
})
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('adminToken');
  let route=[];
  store.dispatch('app/get_year').then(res=>{
      res.forEach(v=>{
        route.push({
          path: "/annual-winner",
          name: "annual-winner",
          index:'2-'+v.id,
          meta: {
            title: v.cn_name||v.en_name,
            contentScroll: true,
          },
        })
      })
    to.matched[0].children[1].children=route
  })
  // from.matched.children[1].children=route
  // console.log(route.value)
  // 设置标题
  // if(to.meta.title) {
  //     document.title = to.query.pageName || to.meta.subTitle || to.meta.title;
  // }
  // if(to.name !== 'Login' && !token) {
  //   next({
  //     name: 'Login'
  //   })
  // }else {
  //   next()
  // }
  // TODO 自动登录 保存token48个小时
  next()
})
export default router
