import Layout from "../Layout/index.vue";
const routes = [
  {
    name: "Login",
    path: "/login",
    component: () => import("../pages/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../pages/register/index.vue"),
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../pages/forget/index.vue"),
  },
  {
    path: "/",
    component: Layout,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "home",
        index:'1',
        meta: {
          title: "首页",
          contentScroll: true,
        },
        component: () => import("../pages/home/index.vue"),
      },
      {
        path: "/annualWinner",
        name: "annualWinner",
        index:'2',
        meta: {
          title: "年度入选者",
          contentScroll: true,
        },
        children: [],
      },
      {
        path: "/information",
        name: "information",
        index:'3',
        meta: {
          title: "资讯",
          contentScroll: true,
        },
        component: () => import("../pages/information/index.vue"),
      },
      {
        path: "/about",
        name: "about",
        index:'4',
        meta: {
          title: "关于",
          contentScroll: true,
        },
        children: [
          {
            path: "/about-index",
            name: "about-index",
            title: "AboutTR35",
            index:'4-1',
            meta: {
              title: "AboutTR35",
              contentScroll: true,
            },
          },
          {
            path: "/about-index",
            name: "about-index",
            title: "SelectionRules",
            index:'4-2',
            meta: {
              title: "Criteria",
              contentScroll: true,
            },
          },
          {
            path: "/about-index",
            title: "RegistrationInstructions",
            name: "about-index",
            index:'4-3',
            meta: {
              title: "Applicationguide",
              contentScroll: true,
            },
          },
          {
            path: "/about-index",
            name: "about-index",
            title: "FAQ",
            index:'4-4',
            meta: {
              title: "FAQ",
              contentScroll: true,
            },
          },
        ],
      },
      {
        path: "/annual-winner",
        name: "annual-winner",
        meta: {
          title: "年度入选者",
          contentScroll: true,
        },
        hidden:false,
        component: () => import("../pages/annualWinner/index.vue"),
      },
      {
        path: "/video-details",
        name: "video-details",
        hidden:false,
        index:'3-1',
        meta: {
          title: "视屏详情",
          contentScroll: true,
        },
        component: () => import("../pages/information/components/videoDetails.vue"),
      },
      {
        path: "/about-index",
        name: "about-index",
        hidden:false,
        meta: {
          title: "关于",
          contentScroll: true,
        },
        component: () => import("../pages/about/index.vue"),
        // component: () => import("../pages/about/aboutTr35.vue"),
      },
      {
          path: '/evaluate',
          name: 'evaluate',
          hidden:false,
          component: () => import('../pages/evaluate/index.vue')
      },
      {
          path: '/apply',
          name: 'apply',
          hidden:false,
          meta: {
            title: "报名入口",
            contentScroll: true,
          },
          component: () => import('../pages/apply/index.vue')
      },
      {
          path: '/forget',
          name: 'forget',
          hidden:false,
          meta: {
            title: "忘记密码",
            contentScroll: true,
          },
          component: () => import('../pages/forget/index.vue')
      },
      {
          path: '/recommend',
          name: 'recommend',
          hidden:false,
          meta: {
            title: "推荐信",
            contentScroll: true,
          },
          component: () => import('../pages/recommend/index.vue')
      },
      {
        path: '/codeofjudges',
        name: 'codeofjudges',
        hidden:false,
        meta: {
          title: "评委守则",
          contentScroll: true,
        },
        component: () => import('../pages/review/codeofjudges.vue')
    },
    {
        path: '/review-index',
        name: 'review',
        hidden:false,
        meta: {
          title: "评委评审",
          contentScroll: true,
        },
        component: () => import('../pages/review/index.vue')
    },
    {
      path: '/confirm',
      name: 'confirm',
      hidden:false,
      meta: {
        title: "确认评审",
        contentScroll: true,
      },
      component: () => import('../pages/review/components/confirm.vue')
  },
  {
      path: '/details',
      name: 'details',
      hidden:false,
      meta: {
        title: "查看详情",
        contentScroll: true,
      },
      component: () => import('../pages/review/components/details.vue')
  },
      {
          path: '/conven',
          name: 'conven',
          hidden:false,
          component: () => import('../pages/conven/index.vue')
      }
    ],
  },
];
export default routes;
