// import { createStore, useStore as vuexUseStore } from 'vuex'
// import app from './modules/app'
// import getters from './getters'
// const modules = {
//   app
// }
// export default createStore({
//   modules
// })

// // 因有辅助函数,优先使用辅助函数
// export const useStore = () => {
//   return vuexUseStore()
// }

import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

// Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters
})

export default store