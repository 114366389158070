
const getters = {
  activeIndex: state => state.app.activeIndex,
  language: state => state.app.language,
  wardsList: state => state.app.wardsList,
  judgesList: state => state.app.judgesList,
  adoutData: state => state.app.adoutData,
  code: state => state.app.code,
  menu_title:state => state.app.menu_title,
  newsListdata:state => state.app.newsListdata,
  adlistFooter:state => state.app.adlistFooter,
  activityBannerList:state => state.app.activityBannerList,
}

export default getters
