import axios from 'axios'
import Router from '../router'
import { warning } from './message'
import { useRouter } from 'vue-router';
import {getCurrentInstance} from 'vue'
import { ERROR_CODE } from './errorCode';
const route = useRouter()
// const baseURL = window.env.baseUrl;
const baseURL = process.env.VUE_APP_BASEURL;
const instance = axios.create({
    // withCredentials: true,
    baseURL
})
const ajax = ({ url, data, params, method, contentType }) => {
    // const token = localStorage.getItem('adminToken')
    const token = localStorage.getItem('adminToken')
    const isChinese = localStorage.getItem('language') === 'en' ? false : true 
    return new Promise(resolve => {
        instance({
            method: method,
            url: url,
            // [method == 'get' ? 'params' : 'data']: data,
            data,
            params,
            headers: {
                "Content-Type": contentType,
                "token": token
            }
        }).then(res => {
            if(res.data.code !== 10000){
                const Arr = ERROR_CODE.filter(item => item.code === res.data.code)
                // console.log('Arr', Arr)
                if(Arr.length){
                    if (res.data.code === 20004 || res.data.code === 20005){
                        Router.replace({
                            name: 'Login'
                        })
                    }else if(location.href.includes('/login') || location.href.includes('/register') || location.href.includes('/forget')){
                        if(res.data.code === 20003){
                            // isChinese ? warning('邮箱不存在，请注册后使用！') : warning('This email does not exist, please sign up first.')
                            warning('This email address does not exist, please register.')
                        }else if(res.data.code === 20002){
                            // isChinese ? warning('用户邮箱已存在！') : warning('This email has existed!')
                            warning('This email has already been registered.')
                        } else {
                            // isChinese ? warning(Arr[0].cnMessage) : warning(Arr[0].enMessage)
                            warning(Arr[0].enMessage)
                        }
                    }else if(location.href.includes('/recommend')){
                        warning(Arr[0].enMessage)
                    } else {
                        isChinese ? warning(Arr[0].cnMessage) : warning(Arr[0].enMessage)
                    }
                } else {
                    if (location.href.includes('/login') || location.href.includes('/register') || location.href.includes('/forget') || location.href.includes('/recommend')|| location.href.includes('/apply')){
                        warning(`The server is unavailable, please try again later![Error code: ${res.data.code}]`)
                    } else {
                        isChinese ? warning(`系统错误，请稍后再试！[错误码：${res.data.code}]`) : 
                        warning(`The server is unavailable, please try again later![Error code: ${res.data.code}]`)
                    }
                }
            }
            // if([10001, 10002, 10003].includes(res.data.code)) {
            //     warning(res.data.msg)
            //     // Router.replace({
            //     //     name: 'Login'
            //     // })
            //     return ;
            // }
            resolve(res)
        })
    })
}
const GET = async({ url, data, params, contentType = 'application/json' }) => {
    const result = await ajax({
        url,
        data,
        params,
        method: 'get',
        contentType
    })
    return Promise.resolve(result)
}
const POST = async({ url, data, params, contentType = 'application/json' }) => {
    const result = await ajax({
        url,
        data,
        params,
        method: 'post',
        contentType
    })
    return Promise.resolve(result)
}
const PUT = async({ url, data, params, contentType = 'application/json' }) => {
    const result = await ajax({
        url,
        data,
        params,
        method: 'put',
        contentType
    })
    return Promise.resolve(result)
}
const DELETE = async({ url, data, params, contentType = 'application/json' }) => {
    const result = await ajax({
        url,
        data,
        params,
        method: 'delete',
        contentType
    })
    return Promise.resolve(result)
}

export {
    GET,
    POST,
    PUT,
    DELETE
}