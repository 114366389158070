import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import router from './router'
import './styles/element/style.css'
import './utils/reset.css'
import './utils/common.less'
import store from './store'
import i18n from './i18n'
import 'lib-flexible'
import * as Icons from '@element-plus/icons-vue'
const app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn,
    i18n: (key, value) => i18n.t(key, value)
})
app.use(router)
app.use(i18n)
app.use(store).mount('#app')
app.config.globalProperties.$year = 2024
Object.keys(Icons).forEach(key => {
app.component(key, Icons[key])
})