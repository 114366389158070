<template>
  <el-container class="layout__container" direction="vertical">
    <Header  class="layout__header" />
    <router-view class="component-main"></router-view>
    <Footer  class="layout__footer" />
  </el-container>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import BreadCrumb from "../components/BreadCrumb/index.vue";
import { ref, watchEffect, computed } from "vue";
import { useRoute } from "vue-router";
export default {
  components: {
    Header,
    Footer,
    BreadCrumb,
  },
  setup() {
    // 处理路由面包屑
    const route = useRoute();
    const pathList = {
      person: "人物",
      company: "公司",
      reports: "报道",
      patent: "专利",
      organization: "机构",
      event: "事件",
      literature: "文献",
      books: "著作",
      tags: "标签",
      users: "用户",
      feedbacks: "反馈",
      audits: "审核",
      operating: "运营",
      messages: "消息",
    };
    const matchPath = (route, path) => {
      return route !== path && route.includes(path);
    };
    const breadList = computed(() => {
      const routePath = route.path;
      if (routePath.match(/\//g).length >= 2) {
        // 多级
        const location = routePath.split("/")[1];
        if (!routePath.includes(location)) return [];
        if (
          routePath.includes("person/edit") ||
          routePath.includes("patent/edit") ||
          routePath.includes("company/edit") ||
          matchPath(routePath, "/person/detail") ||
          matchPath(routePath, "/organization/investment") ||
          matchPath(routePath, "/organization/social") ||
          matchPath(routePath, "/organization/school")
        ) {
          // 获取地址栏参数
          if (
            routePath.includes("organization/social/edit") ||
            routePath.includes("organization/school/edit") ||
            routePath.includes("organization/investment/edit") ||
            matchPath(routePath, "/organization/investment/detail")
          ) {
            return [
              {
                path: route.meta.homePath || "",
                name: pathList[location],
              },
              {
                path: route.meta.backPrevPath || "",
                name: route.meta.title,
              },
              {
                path: route.meta.subBackPath || "",
                name: route.meta.subTitle || route.query.pageName,
              },
              {
                path: "",
                name: route.meta.nowTitle || route.query.pageName,
              },
            ];
          } else {
            return [
              {
                path: route.meta.homePath || "",
                name: pathList[location],
                notBack: route.query.fromHome == 1,
              },
              {
                path: route.meta.backPrevPath || "",
                name: route.meta.title,
                notBack: route.query.fromHome == 1,
              },
              {
                path: "",
                name: route.query.pageName || route.meta.subTitle,
              },
            ];
          }
        } else {
          // 层级结构的两级面包屑第一层是不能跳转的 从首页快捷进来的也不能跳转
          if (
            [
              "/organization/investment",
              "/organization/school",
              "/organization/social",
              "/event/invest",
              "/event/exit",
            ].includes(routePath) ||
            route.query.fromHome == 1
          ) {
            return [
              {
                path: route.meta.backPrevPath || "",
                notBack: true,
                name: pathList[location],
              },
              {
                path: "",
                name: route.meta.title,
              },
            ];
          } else {
            return [
              {
                path: route.meta.backPrevPath || "",
                name: pathList[location],
              },
              {
                path: "",
                name: route.meta.title,
              },
            ];
          }
        }
      } else {
        // 一级
        return [
          {
            path: "",
            name: route.meta.title,
          },
        ];
      }
    });

    // 需要右侧整理滚动的页面
    const contentScrollRef = ref(false);
    watchEffect(() => {
      if (route.meta.contentScroll) {
        contentScrollRef.value = true;
      } else {
        contentScrollRef.value = false;
      }
    });

    return {
      route,
      breadList,
      contentScroll: contentScrollRef,
    };
  },
};
</script>

<style scoped lang="less">
.layout__container {
  width: 100%;
  min-height: 100vh;
  // background: #f6f6f6;
  .layout__header {
    // height: 83px;
    width: 100%;
    background: #ffffff;
    // display: flex;
    align-items: center;
  }
  .layout__footer{
    width: 100%;
    background: #ffffff;
    // display: flex;
    align-items: center;
  }
  // .component-main {
  //   width: 1200px;
  //   margin: 20px auto 70px;
  //   background: #fff;
  // }
}
</style>
