import { ElMessage } from 'element-plus'
const elementMessage = (type = 'info', message = '', duration = 2000) => {
    ElMessage({
        type,
        duration,
        message
    })
}
const success = (title, duration) => {elementMessage('success', title, duration)}
const warning = (title, duration) => {elementMessage('warning', title, duration)}
const info = (title, duration) => {elementMessage('info', title, duration)}
const error = (title, duration) => {elementMessage('error', title, duration)}
export {
    success,
    warning,
    info,
    error
}
