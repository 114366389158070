<template>
  <el-config-provider :locale="locale">
    <RouterView id="app" />
  </el-config-provider>
</template>
<script>
import { defineComponent, ref, watch, computed } from "vue";
import zhLocale from "element-plus/lib/locale/lang/zh-cn";
import enLocale from "element-plus/lib/locale/lang/en";
import { useStore } from "vuex";
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
export default defineComponent({
  name: "App",
  setup() {
    let store = useStore();
    const router = useRouter();
    const path = router.currentRoute.value;
    let locale = ref(store.getters.language === "en" ? enLocale : zhLocale);
    const lang = computed(() => store.getters.language);
    watch(lang, (newLang) => {
      console.log('newLang',newLang)
      locale.value = newLang === "en" ? enLocale : zhLocale
    });
    return {
      locale,
    };
  },
});
</script>
