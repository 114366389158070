import enLocale from 'element-plus/lib/locale/lang/en'

const en = {
  el: enLocale.el,
  language: {
    chinese: 'CN',
    english: 'EN'
  },
  button: {
    query: 'Query',
    reset: 'Reset',
    read: 'Read',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    batchDelete: 'Batch delete',
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    login: 'Login',
    register:'Register',
    logout: 'Log Out',
    Review:'Review',
    nomination:'Nominate someone',
    signUp:'Apply here',
    set: 'Set',
    editInfo: 'Edit information',
    maximize: 'Maximize',
    reduction: 'Reduction',
    clear: 'Clear',
    enable: 'Enable',
    disable: 'Disable',
    download: 'Download',
    back:'Return to home page',
    backs:'back',
    News:' News',
    Video:'Video',
    More:'More',
    LoadMore:'Load more',
  },
  field: {
    account: 'Account',
    password: 'Password',
    captcha: 'Captcha',
    nickname: 'Nickname',
    mobile: 'Mobile',
    email: 'E-mail',
    oldPassword: 'Original password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    name: 'Name',
    fullName: '{0} name',
    title: 'Title',
    type: 'Type',
    remark: 'Remark',
    start: 'Start',
    end: 'End',
    state: 'State',
    time: '{0} time',
    date: '{0} date',
    create: 'Creation',
    update: 'Update',
    sort: 'Sort',
    icon: 'Icon',
    is: 'Is {0}',
    operation: 'Operation'
  },
  rule: {
    notBlank: '{0} cannot be empty',
    incorrect: '{0} format is incorrect',
    notConsistent: '{0} is not consistent with {1}'
  },
  title:{
    AwardCategory:'Five Categories of Innovators',
    AwardCon:'Winners will be honored in one of the following categories based on the specifics of their innovative contributions and the resulting impact.',
    next:'Will you be the next one?'
  },
  menu: {
    home: 'Home',
    annualWinner:'Annual list',
    information:'News',
    about:'About',
    AboutTR35:'About TR35',
    SelectionRules:'Criteria',
    RegistrationInstructions:'Application Guide',
    FAQ:'FAQ',
    demo: 'Demo',
    set: 'Set'
  },
  footer:{
    Contact:'Contact us',
    Links:'Links',
    MIT:'MIT Technology Review China',
    TR35:'TR35 Asia Pacific'
  },
  tip: {
    tips: 'Tips',
    confirmTips: 'Confirm the [{0}] operation',
    confirmOptionTips: 'Confirm the [{1}] operation on [id={0}]?',
    success: 'Operation successful',
    fail: 'Operation failed',
    menuCollapse: 'Menu bar expand / collapse',
    exitAccount: 'Are you sure you want to exit the current account?',
    comingSoon:"Coming soon...",
    nodata:'No data',
    loadingFailed:"Loading failed",
    TOP:'TOP'
  },
  base: {
    menu: {
      chinese: 'Chinese',
      english: 'English',
      authMark: 'Authorization mark',
      display: 'display',
      alive: 'keep alive',
      tab: 'display in tab',
      multiple: 'spupport multi tab',
      catalog: 'Catalog',
      menu: 'Menu',
      button: 'Button',
      superior: 'Superior',
      authPlaceholder: 'Multiple items are separated by semicolons, such as: user:page,user:create',
      selected:"Winners",
      Judges:'Judges',
      yearCon:'*Categories of Innovators are displayed in reverse alphabetical order, and the name list is sorted alphabetically according to their last names.',
      yearCon2 :'*The name list is sorted alphabetically according to their last names.',
    },
    role: {
      role: 'Role',
      auth: 'Authorize'
    },
    task: {
      immediately: 'Immediately',
      resume: 'Resume',
      pause: 'Pause',
      normal: 'Normal',
      parameter: 'Parameter',
      expression: '{0} expression',
      batch: 'Batch {0}'
    },
    file: {
      file: 'File',
      original: 'Original',
      actual: 'Actual',
      extension: 'Extension',
      size: 'Size',
      physicalPath: 'Physical path',
      virtualPath: 'Virtual path',
      upload: 'Upload'
    },
    backup: {
      backup: 'Backup',
      backupMode: 'Backup mode',
      manual: 'Manual',
      automatic: 'Automatic',
      clearDatabase: 'Clear database',
      databaseName: 'Database name',
      physicalPath: 'Physical path',
      virtualPath: 'Virtual path',
      command: 'Command',
      recovery: 'Recovery'
    },
    set: {
      language: 'Language',
      fixedNavBar: 'Fixed navigation bar',
      showTabBar: 'Show tab bar'
    }
  }
}

export default en
