<template>
  <div class="header-container">
    <div class="ad-header" v-if="adList.length>0">
      <div class="banner-box">
            <el-carousel trigger="click" :autoplay='true' height="100px" :interval='4000'>
                <el-carousel-item v-for="item in adList" :key="item.ad_id">
                 <template v-if="item.ad_jump_url">
                    <a :href="item.ad_jump_url" target="_blank">
                         <img v-if="width>750" :src="item.ad_img_url" alt="">
                        <img v-else :src="item.ad_img_m_url" alt="">
                    </a>
                  </template>
                  <template v-else>
                    <img  v-if="width>750" :src="item.ad_img_url" alt="">
                    <img v-else :src="item.ad_img_m_url" alt="">
                  </template>
                </el-carousel-item>
            </el-carousel>
        </div>
  </div>
    <el-header class="item-center" style="padding:0;height:84px" :class="languageType === 'en' && 'item-center-en'">
       <div style="display: flex;align-items: center;">
          <div><img src="@/assets/images/logo.svg" alt=""  class="header__logo"/></div>
          <div v-if="width<=750">
              <el-icon style="font-size: 30px;" v-if="!isCollapse" @click="toggleSiderBar"><expand /></el-icon>
              <el-icon style="font-size: 30px;" v-else @click="toggleSiderBar"><fold /></el-icon>
          </div>
        <el-menu :default-active="getters.activeIndex.value" :unique-opened='true' class="header__menu el-menu-demo" 
        :style="{display:width<=750?(isCollapse?'block':'none'):'flex'}" 
        :mode="width<=750?'vertical':'horizontal'">
          <template v-for="item in menuList.children" :key="item.path">
           <template v-if="width>750">
                <el-sub-menu :index="item.index" v-if="item.children&&item.children.length&&item.name!=='about'">
                  <template #title ><span style="font-size:16px;">{{t('menu.'+item.name)}}</span></template>
                    <el-menu-item v-for="chil in item.children" :key="chil.path"  :index="chil.index" @click="menuItemLink(chil)">
                     <span> {{item.index==2?chil.meta.title:t('menu.'+chil.title)}}</span>
                      </el-menu-item>
                </el-sub-menu>
                <el-menu-item v-else-if="typeof(item.hidden)=='undefined'" :index="item.index" @click="menuItemLink(item)">
                 <span>{{t('menu.'+item.name)}}</span>
                </el-menu-item>
            </template>
            <template v-else>
                <el-sub-menu :index="item.index" v-if="item.children&&item.children.length">
                  <template #title ><span style="font-size:16px;">{{t('menu.'+item.name)}}</span></template>
                    <el-menu-item v-for="chil in item.children" :key="chil.path"  :index="chil.index" @click="menuItemLink(chil)">
                     <span> {{item.index==2?chil.meta.title:t('menu.'+chil.title)}}</span>
                      </el-menu-item>
                </el-sub-menu>
                <el-menu-item v-else-if="typeof(item.hidden)=='undefined'" :index="item.index" @click="menuItemLink(item)">
                 <span>{{t('menu.'+item.name)}}</span>
                </el-menu-item>
            </template>
          </template>
        </el-menu>
        </div>
     <div style="justify-content: end;display: flex;" class="header__infos">
        <!-- <el-progress :text-inside="true" :stroke-width="24" :percentage="100" status="success"></el-progress> -->
      <div class="header__info">
        <div class="entry sign_up" @click="handleApply" v-if="apply_list.value&&apply_list.value.apply_state==1">{{ t('button.signUp') }}</div>
        <div class="entry nomination" @click="nominateApply" v-if="apply_list.value&&apply_list.value.nominate_state==1" 
        style="color: #000;border:1px solid #000">{{ t('button.nomination') }}</div>
        <div class="change" v-if="!isIncludesApply">
          <span @click="() => changeLanguage('cn')" :class="languageType === 'cn' && 'active'">{{ t('language.chinese') }}</span>
          ｜
          <span :class="languageType === 'en' && 'active'" @click="() =>changeLanguage('en')">{{ t('language.english') }}</span>
        </div>
        <!-- <el-avatar class="header__info-avatar" icon="el-icon-user-solid" /> -->
        <!-- <img src="@assets/images/avatar.png" alt="" class="header__info-avatar" /> -->
      </div>
      <el-dropdown @command="handleCommand" class="header__dropdown" v-if="adminEmail" style="line-height:30px">
        <!-- <el-avatar class="header__info-avatar" icon="el-icon-user-solid" /> -->
        <div>
          <!-- {{adminEmail}} -->
          <img src="@/assets/images/avatar.svg" alt=""  class=""/>
          </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="Review"
             v-if="identityid&&identityid.activity_year==identityid.judges_year">Review</el-dropdown-item>
            <el-dropdown-item command="loginout">{{ t('button.logout') }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div v-else class="logoin">
        <span style="cursor: pointer;margin-right:20px" @click='register'>{{ t('button.register') }}</span>
        <span style="cursor: pointer;" @click="login">{{ t('button.login') }}</span>
      </div>
     </div>
    </el-header>
  </div>
</template>
<script>
  import { defineComponent, ref, computed, watch,reactive } from 'vue'
  import { ElMessageBox, ElMessage } from 'element-plus'
  import { useRouter, onBeforeRouteUpdate } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import store from '@/store'
  import { Ajax, MessageTips } from '@/utils'
  import {useGetters} from '@/store/use.js'
  import {mapState} from 'vuex'
  export default defineComponent({
    name: 'LayoutHeader',
    setup() {
      const router = useRouter();
      const getters=useGetters(['activeIndex']);
      const isIncludesApply = ref(router.currentRoute.value.path.includes('/apply'))
      const menuList=router.currentRoute.value.matched[0],width=ref(document.body.clientWidth);
      const adList=ref([])
      const navList = ref([
        { name: '首页', path: '/index', id: 0 },
        { name: '年度榜单', path: '/index', id: 1 },
        { name: '动态', path: '/index', id: 2 },
        { name: '关于', path: '/index', id: 3 },
        // { name: '动态', path: '/evaluate', id: 2 },
        // { name: '关于', path: '/recommend', id: 3 }
      ])
      const path = router.currentRoute.value.path;
      store.dispatch('app/getad_list', {num:1,type:'ad_head'}).then(res=>{
          adList.value=JSON.parse(sessionStorage.getItem('ad_head'))
      });
       //获取底部广告数据
       if(path=='/index'||path=='/annual-winner'||path=='/information'||path=='/about-index'){
          let num=ref(path=='/index'?2:path=='/annual-winner'?3:path=='/information'?5:6)
            store.dispatch('app/getad_list', {num:num.value,type:''})
        }
        if(path=='/apply'){
          store.dispatch('app/setLanguage', 'en')
        }
      // store.dispatch('app/getad_list', 2,'');
      const isCollapse=ref(false),isShowCh=ref(false);
      const activeIndex = ref(sessionStorage.getItem('activeIndex')||'1');//获取当前路由
      const languageType = ref(localStorage.getItem('language') || 'cn')
      const adminEmail = ref(localStorage.getItem('adminEmail') || '');
      const identityid=ref(JSON.parse(localStorage.getItem('year')))
      window.onresize = () => {
        return (() => {
          // location.reload();
            // isCollapse.value=!isCollapse.value
            // if(document.body.clientWidth<=750)location.reload();
          width.value=document.body.clientWidth;
        })();
      };
      if(width.value>750){
        setTimeout(()=>{
               document.getElementsByClassName('el-menu--popup')[0].style.minWidth='100px';
              //  document.getElementsByClassName('el-menu--popup')[1].style.minWidth='100px';
            },2000)
      }
      const { t, locale } = useI18n()
      const logout = async() => {
        const res = await Ajax.POST({
          url: '/api/logout'
        })
        if (res.data.code === 10000){
          ElMessage({ type: 'success', message:'Log out successfully!'})
          localStorage.removeItem('adminEmail')
          localStorage.removeItem('adminToken')
          localStorage.removeItem('year')
          sessionStorage.removeItem('identity')
          localStorage.removeItem('token_expired')
          // router.replace('/login')
          sessionStorage.setItem('activeIndex',1);
          store.dispatch('app/setactiveIndex', 1);
          router.push('/index');
          setTimeout(()=>{
            location.reload();
          },500)
           
        }
      }
      const token_expired=ref(localStorage.getItem('token_expired'))
      const current_time=ref(Date.parse(new Date())/1000)//当前时间戳
      if(token_expired.value&&token_expired.value<current_time.value){
          localStorage.removeItem('adminEmail')
          localStorage.removeItem('adminToken')
          localStorage.removeItem('year')
          localStorage.removeItem('token_expired')
          sessionStorage.setItem('activeIndex',1);
      }
      const login=()=>{//登录
        router.push('/login')
      }
      const register=()=>{//注册
        router.push('/register')
      }
      const handleCommand = (command) => {
        switch(command){
          case 'loginout':
            loginout()
            break;
          case 'Review':
             store.commit("app/SET_ADLISTFOOTER",[])
             console.log()
            if(sessionStorage.getItem('is_agree_rule')!=='0') router.push('/review-index')
            else router.push('/codeofjudges')
            break;
        }
      }
      const loginout=()=>{
         let language=localStorage.getItem('language');
          let titleText =language=='cn'?"确定要退出登录吗？": 'Are you sure you want to log out?';
          let tipText =language=='cn'?'提示': 'Tips';
          ElMessageBox.confirm('Are you sure you want to log out?', 'Tips', {
            confirmButtonText:'Confirm',
            cancelButtonText:'Cancel',
            type: 'warning'
          })
            .then(() => {
              logout()
            })
            .catch(() => {})
      }
      const menuItemLink = (item) => {//路由跳转
        store.state.app.menu_title=item.meta.title;
         isCollapse.value=!isCollapse.value
        if(item.path==='/annual-winner'||item.path=='/about-index'&&item.path!=='/about'){
          if(item.path=='/annual-winner'){
            store._state.data.app.wardsList=[];
            store._state.data.app.judgesList=[];
            store._state.data.app.yearType=1;
            store.dispatch('app/getAwards', item.meta.title)
            store.dispatch('app/getad_list', {num:3,type:''})
            store.dispatch('app/getActivityBanner', item.meta.title)
          } else{
            store.dispatch('app/getad_list', {num:6,type:''})
            store.dispatch('app/getAboutData', item.meta.title)
          } 
          localStorage.setItem('menu_title',item.meta.title)
           router.push({
            path:item.path,
            query:{ title:item.meta.title}
            })
        }else{
          if(item.path=='/about'){
             store.dispatch('app/getad_list', {num:6,type:''})
             store.dispatch('app/getAboutData', 'AboutTR35');
             store.state.app.menu_title='AboutTR35';
             localStorage.setItem('menu_title','AboutTR35');
             router.push({
              path:'/about-index',
              query:{ title:'AboutTR35'}
              })
          }else{
             router.push(item.path);
             if(item.path==='/index')
               store.dispatch('app/getad_list', {num:2,type:''})
              else if(item.path==='/information'){
                sessionStorage.removeItem('ind')
                store.dispatch('app/getad_list', {num:5,type:''})
              }
          } 
        }
        sessionStorage.setItem('activeIndex',item.index);
        store.dispatch('app/setactiveIndex', item.index)
        activeIndex.value = item.index;
        isIncludesApply.value = false
        store.dispatch('app/setLanguage', localStorage.getItem("language") || "cn")
      }
     
      const getLang = () => {
        let result
        switch (localStorage.getItem('language')) {
          case LanguageType.Chinese:
            result = t('language.chinese')
            break
          case LanguageType.English:
            result = t('language.english')
            break
          default:
            result = t('language.chinese')
            break
        }
        return result
      }

      const lang = computed(() => getLang())

      const changeLanguage = (command) => {
        localStorage.setItem('language', command)
        store.dispatch('app/setLanguage', command)
        locale.value = command
        languageType.value = command
        let path=router.currentRoute.value.path;
        if(path=='/index'||path=='/information'){
          // let per_page= sessionStorage.getItem('per_page')
          let searchForm=reactive({
            per_page:10,
            current_page:1,
            language:command=='cn'?1:2
          })
          store.dispatch("app/getnews_list",searchForm);
        }
      }

      const handleApply = () => {
        localStorage.getItem('adminToken') ? router.push('/apply') : router.push('/login')
         store.dispatch('app/setLanguage', 'en')
         isIncludesApply.value = true
      }

      onBeforeRouteUpdate((to) => {
        // if(to.path !== '/apply'){
        //   localStorage.setItem('step', 0)
        // }
        if (to.path.includes('/apply') || to.path.includes('/login') || to.path.includes('/register') || to.path.includes('/forget') || to.path.includes('/recommend')){
          isShowCh.value = false
        } else {
          isShowCh.value = true
        }
      });
      const toggleSiderBar=()=>{
        isCollapse.value=!isCollapse.value
      }
      const apply_list=reactive({})
      const applyEntrance=()=>{//报名&提名入口
        Ajax.POST({
          url: "/api/website/applyEntrance",
        }).then((res) => {
          if (res.data.code === 10000) {
            apply_list.value=res.data.data;
          }
        });
      }
      const nominateApply=()=>{//提名跳转
      let url=apply_list.value.nominate_link;
      let href=url.indexOf('http://')!==-1?url:'http://'+url;
        window.open(href)
      }
      applyEntrance();
      return {
        getters,
        login,
        identityid,
        register,
        menuItemLink,
        handleCommand,
        navList,
        menuList,
        t,
        lang,
        changeLanguage,
        languageType,
        adminEmail,
        activeIndex,
        isIncludesApply,
        handleApply,
        width,
        isCollapse,
        toggleSiderBar,
        apply_list,
        nominateApply,
        adList
      }
    }
  })
</script>
<style lang="scss" scoped>
// :deep(.ad-header){
//   .el-carousel:hover{
//     .el-carousel__arrow--left,.el-carousel__arrow--right{display: none !important;}
//     }
// } 
.el-menu--horizontal{
       .el-menu--popup{
         min-width: 120px !important;
        background: red($color: #000000);
          li{justify-content: center;font-weight: 600;}
          li:hover{ 
            color: #E34C3E !important;
            background: #fff !important;
          }
        }
       } 
    .banner-box{
            width: 100%;
            text-align: center;
            img{
                width: 100%;
                height: 100%;
                // object-fit: cover;
            }          
        }
  .header-container {
    .item-center {
      width: 1200px;
      // width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // justify-content: center;
    }
    .logoin{
      line-height:30px;
      font-size:16px;
      line-height: 34px;
      span:hover{
         color: #E34C3E ;
      }
    }
  }
  .header__logo {
    margin: 0 90px 0 0;
    width: 113px;
    height: 40px;
  }
  .el-menu--horizontal{
    border:none;
    font-weight: 600;
  }
  :deep(.header__menu) {
    width: 450px;
    .el-sub-menu .el-sub-menu__title{
      font-size: 16px;
      padding: 0px;
      span{height: 45px;line-height: 45px;}
      span:hover{color: #ED3524;}
      .el-icon{display: none;}
    }
    .el-sub-menu__title span{line-height: 48px !important}
    .el-menu-item,.el-sub-menu{ margin: 0 60px 0 0;}
    .el-menu-item:last-child,.el-sub-menu:last-child{margin: 0}
    .el-sub-menu__icon-more{display: none !important;}
    .el-menu-item{
      font-size: 16px;
      // font-weight: 400;
      color: #000000;
      padding: 0;
      background-color: #fff;
      // margin: 0 10px;
    }
    .is-active,.is-active .el-sub-menu__title,.el-menu-item {border: none; background-color: #fff !important;}
      @media screen and (min-width: 750px){
      .is-active span{
       background-color: #fff !important;
       border-bottom: 4px solid #ED3524 !important;   
       color: #ED3524 !important;
       height: 45px; line-height: 45px;
       }
      }
   
    .el-menu-item:hover{background-color: #fff;border: none;}
    .el-menu-item span:hover{
       height: 45px; line-height: 45px;
      border-bottom: 4px solid #ED3524;
      color: #ED3524 !important;
      // border-bottom: 1px solid #ffd04b;
    }
    
   
  }
  
  .header__info {
    display: flex;
    align-items: center;
    .entry {
      // min-width: 125px;
      // width: auto;
      height: 34px;
      line-height: 30px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid #E34C3E;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e34c3e;
      text-decoration: none;
      margin-right: 20px;
      cursor: pointer;
      padding: 0 15px;
      white-space: nowrap;
    }
    .sign_up:hover{background: #e34c3e;color: #fff;}
    .nomination:hover{background: #222222;color: #fff !important;}
    .change {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-right: 30px;
      margin-left: 10px;
      // width: 92px;
      white-space: nowrap;
      span {
        cursor: pointer;
      }
       span:hover{
          color: #E34C3E ;
       }
      .active {
        color: #e34c3e;
      }
    }
    .header__info-avatar {
      width: 40px;
      height: 40px;
      // line-height: 45px;
    }
  }
  :deep(.header__dropdown) {
    &.el-dropdown {
      // color: #fff;
    }
  }
  :deep(.el-dropdown-menu__item:not(.is-disabled)):hover,
  :deep(.el-dropdown-menu__item:focus) {
    color: #E34C3E;
    background-color: #fff;
  }
  @media screen and (min-width: 375px) and (max-width: 750px){
    :deep(.item-center){ .el-sub-menu .el-sub-menu__title:hover{background: #fff !important;}}
   .header-container{
     .el-header{
       width: 100%;
       padding:0 10px !important;
       position: relative;
       justify-content: start;
       img{
         margin: 0 18px 0 0;
         width: 110px;
       }
       .header__infos{width: auto !important;}
       ul{
        //  width: 140px !important;
         width: auto !important;
        top: 5%;
        left: 46%;
        z-index: 999;
        position: absolute;
        .el-menu-item{
          min-width: auto ;
        }
        :deep(.el-sub-menu__title){padding: 0px 40px 0 20px !important}
        .is-active{/*border-bottom: 1px solid #e34c3e !important;*/color: #e34c3e ;}
       }
       .entry{font-size: 14px;}
       .sign_up,.header__dropdown,.logoin{display: none;}
       .nomination{margin: 0 8px 0 12px;padding: 0 10px;width:auto;}
       .change{margin-right: 0px;width:auto;font-size: 15px;}
      //  .el-menu--inline li,.header__menu>.el-menu-item{padding-left: 10px !important;}
     }
     .item-center-en{
       .header__logo{margin: 0 5px 0 0;}
       .nomination{margin: 0 0px 0 5px;padding: 0 5px;font-size: 13px;}
       .change{font-size: 13px;}
       
     }
   }
  }
</style>
