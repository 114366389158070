import { Ajax } from "@/utils";
import {set,ref,reactive} from 'vue'
const state = {
  activeIndex:sessionStorage.getItem('activeIndex')||"1",//当前路由激活码
  language: localStorage.getItem("language") || "cn",
  yearType: 1, //1：入选者，2：评委
  wardsList: {}, //入选者数据
  judgesList: {}, //评委数据
  Loading:false,
  adoutData:{},//关于-关于TR35、评选规则、报名须知、FAQ
  code:'',//状态码
  menu_title: localStorage.getItem("menu_title")||'',//选择的菜单名
  newsListdata:[],//新闻列表数据
  adlistFooter:[],//底部广告数据
  activityBannerList:{},//年度榜单轮播图数据
};

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language;
  },
  GET_AWARDS: (state, data) => {
    state.wardsList = data;
  },
  GET_JUDGES: (state, data) => {
    state.judgesList = data;
  },
  GET_ABOUTDATA:(state, data)=>{
    state.adoutData = data;
  },
  SET_ACTIVEINDEX:(state, data)=>{
    state.activeIndex = data;
  },
  SET_CODE:(state, data)=>{
    state.code = data;
  },
  SET_NEWSLISTDATA:(state, data)=>{
    state.newsListdata = data;
  },
  SET_ADLISTFOOTER:(state, data)=>{
    state.adlistFooter = data;
  },
  SET_ACTIVITYBANNERLIST:(state, data)=>{
    state.activityBannerList = data;
  },
};

const actions = {
  get_year(){
    return new Promise((resolve, reject) => {
      Ajax.POST({
        url: "/api/getSelect",
        data:{type:'year'},
      }).then((res) => {
        if (res.data.code === 10000) {
          let data=res.data;
          resolve(data.data);
        }
      });
    })
  },
  setactiveIndex({commit},index){
    commit("SET_ACTIVEINDEX", index);
  },
  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language);
  },
  getAwards({ commit }, year) {
    //获取获奖人列表
    commit("GET_AWARDS", []);
    commit("GET_JUDGES", []);
    let url = state.yearType == 1 ? "/api/website/awards" : "/api/website/judges";
    state.Loading=true
    return new Promise((resolve, reject) => {
      Ajax.POST({
        url: url,
        data: { year: year },
      }).then((res) => {
        if (res.data.code === 10000) {
          if (state.yearType == 1){
            try {
                res.data.data.awards.forEach(i=>{
                  i.list = i.list.sort((a,b)=>a.sort-b.sort)
                  i.list.forEach(item=>{
                    item.cn_reason=item.cn_reason.replace(/\n/g,"<br>");
                    item.en_reason=item.en_reason.replace(/\n/g,"<br>");
                    })
                })
            } catch (error) {
            }
            commit("GET_AWARDS", res.data);
          }else{
            try {
                res.data.data = res.data.data.sort((a,b)=>a.sort-b.sort);
          } catch (error) {
          }
            commit("GET_JUDGES", res.data);
          } 
        }
        resolve(res.data.data);
        state.Loading=false
      });
    });
  },
  getActivityBanner({ commit }, year) {
      //获取年度榜单-轮播图
      Ajax.POST({
        url: "/api/website/activityBanner",
        data: { year: year },
      }).then((res) => {
        if (res.data.code === 10000) {
          let data=res.data.data;
          commit("SET_ACTIVITYBANNERLIST",data);
          commit("SET_CODE",res.data.code);
        }
      });
  },
  getAboutData({ commit }, title){
    //获取关于数据
    Ajax.POST({
      url: "/api/website/about",
    }).then((res) => {
      if (res.data.code === 10000) {
        let data=res.data.data;
        let listData=title=="AboutTR35"?data.about_tr35:title=="Criteria"?data.about_selection_rule:title=="Applicationguide"?data.about_notice:data.about_faq;
         commit("GET_ABOUTDATA",listData);
         commit("SET_CODE",res.data.code);
      }
    });
  },
  getJudges_rules({ commit }, title){
    //获取评委守则，评委规则，评委须知
    return new Promise((resolve, reject) => {
      Ajax.POST({
        url: "/api/judges/rules",
      }).then((res) => {
        if (res.data.code === 10000) {
          resolve(res.data.data);
        }
      });
    })
  },
  setcode({ commit }, code){
    commit("SET_CODE",code);
  },
  getvideo_list({ commit }, data){
    //获取视频列表数据
    state.Loading=true
    return new Promise((resolve, reject) => {
      Ajax.POST({
        url: "/api/video/list",
        data:data,
      }).then((res) => {
        if (res.data.code === 10000) {
          let data=res.data;
          resolve(data.data);
          commit("SET_CODE",data.code);
        }
        state.Loading=false
      });
    })
    
  },
  getnews_list({ commit }, data){
    //获取新闻列表数据
    state.Loading=true
    Ajax.POST({
      url: "/api/news/list",
      data:data,
    }).then((res) => {
      state.Loading=false
      if (res.data.code === 10000) {
        let data=res.data.data;
         commit("SET_NEWSLISTDATA",data);
         commit("SET_CODE",res.data.code);
      }
    });
  },
  getad_list({ commit }, type){
    //获取新闻列表数据
    return new Promise((resolve, reject) => {
      Ajax.POST({
        url: "/api/ad/list",
        data:{ad_position:type.num},
      }).then((res) => {
        if (res.data.code === 10000) {
          let data=res.data.data;
          resolve(res.data);
          if(type.type=='ad_head') sessionStorage.setItem('ad_head',JSON.stringify(data))
          else if(type.type=='information_head') sessionStorage.setItem('information_head',JSON.stringify(data))
          else  commit("SET_ADLISTFOOTER",data);
          commit("SET_CODE",res.data.code);
        }
      })
    })
    ;
  }
};
const getters = {
  language: (state) => state.language,
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
