
// 错误码
export const ERROR_CODE = 
[
    {
        code: 20002,
        cnMessage: '系统错误，请稍后再试！[错误码：20002]',
        enMessage: 'The server is unavailable, please try again later![Error code: 20002]',
    },
    {
        code: 20003,
        cnMessage: '系统错误，请稍后再试！[错误码：20003]',
        enMessage: 'The server is unavailable, please try again later![Error code: 20003]',
    },
    {
        code: 20004,
        cnMessage: '登录信息已过期，请重新登录！',
        enMessage: 'Your account has expired, please log in again.',
    },
    {
        code: 20005,
        cnMessage: '登录信息已过期，请重新登录！',
        enMessage: 'Your account has expired, please log in again.',
    },
    {
        code: 30100,
        cnMessage: '您的账号已被禁用，如需启用，请通过邮件联系我们tr35@mittrchina.com',
        enMessage: 'Your account has been disabled. If you need to enable it, please contact us via tr35@mittrchina.com',
    },
    {
        code: 30101,
        cnMessage: '邮箱未激活！',
        enMessage: 'This email has not been activated.',
    },
    {
        code: 30102,
        cnMessage: '邮箱或密码错误，请重新输入',
        enMessage: 'Incorrect email address or password, please try again.',
    },
    {
        code: 30103,
        cnMessage: '验证码错误',
        enMessage: 'Invalid Verification Code',
    },
    {
        code: 30200,
        cnMessage: '推荐人/其他补充性说明不得超过5个',
        enMessage: 'No more than 5 recommenders/supplementary files',
    },
    {
        code: 30201,
        cnMessage: '推荐人不得少于2位',
        enMessage: 'At least 2 references',
    },
    {
        code: 30202,
        cnMessage: '校验码错误',
        enMessage: 'Invalid Verification Code!',
    },
    {
        code: 40001,
        cnMessage: '附件类型有误，请重新上传',
        enMessage: 'The attachment type is incorrect, please upload again',
    },
]

