<template>
  <div class="footer-container">
    <div class="ad-footer" v-if="getters.adlistFooter.value.length>0">
        <div class="banner-box">
            <el-carousel trigger="click" :autoplay='true' height="180px" :interval='4000'>
                <el-carousel-item v-for="item in getters.adlistFooter.value" :key="item.ad_id">
                  <template v-if="item.ad_jump_url">
                    <a :href="item.ad_jump_url" target="_blank">
                        <img v-if="width>750" :src="item.ad_img_url" alt="">
                        <img v-else :src="item.ad_img_m_url" alt="">
                    </a>
                  </template>
                  <template v-else>
                    <img  v-if="width>750" :src="item.ad_img_url" alt="">
                    <img v-else :src="item.ad_img_m_url" alt="">
                  </template>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
    <el-footer class="item-center">
      <!-- <div class="hed-footer"></div> -->
      <div class="con-footer">
        <el-row>
          <el-col :span="8">
            <img src="@/assets/images/footer-icon1.svg" alt="" />
            <img src="@/assets/images/footer-icon2.svg" alt="" style="margin-left: 20px;" />
          </el-col>
          <el-col :span="16" style="font-size: 16px;" :style="{paddingLeft:getters.language.value==='cn'?'98px':'28px'}">
            <div class="email" style="margin-bottom: 18px">
              <span>{{ t("footer.Contact") }}：tr35@mittrchina.com</span>
              <span style="float: right">
                <span class="wx_code">
                  <img class="wx_codeimg" src="@/assets/images/wxcode.png" alt="" style="
                      position: absolute;
                      top: -153px;
                      right: -9px;
                      display: none;
                    " />
                  <img src="@/assets/images/footer-icon4.svg" alt="" style="margin-right: 20px" />
                </span>
                <a href="https://weibo.com/mittechnologyreview" target="_blank"><img
                    src="@/assets/images/footer-icon5.svg" alt=""/></a>
              </span>
            </div>
            <div class="link">
              {{ t("footer.Links") }}：<a href="https://www.mittrchina.com" target="_blank">{{ t("footer.MIT") }}</a>
              &nbsp;丨&nbsp;
              <a href="https://www.technologyreview.com" target="_blank">MIT Technology Review</a>
              &nbsp;丨&nbsp;
              <a href="http://tr35.mittrasia.com/" target="_blank">{{
                t("footer.TR35")
                }}</a>
               丨
              <a href="https://www.deeptechchina.com/" target="_blank">
                DeepTech</a>
            </div>
          </el-col>
        </el-row>
        <div style="
            height: 1px;
            background: rgba(255, 255, 255, 0.25);
            margin:0px;
          "></div>
        <div style="text-align: center; font-size: 14px" class="fot">
          <span>© 2017 京ICP16049925号</span>
          <span class="n">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span style="position: relative">
            <img src="@/assets/images/footer-icon6.png" alt="" style="position: absolute; bottom: 2px" />
            <span style="padding-left: 25px">京公网安备 11010502040579</span>
          </span>
        </div>
      </div>
    </el-footer>
  </div>
</template>
<script>
  import { useI18n } from "vue-i18n";
  import { defineComponent, ref, computed, watch } from "vue";
  import {useGetters} from '@/store/use.js'
  import { useRouter, onBeforeRouteUpdate } from 'vue-router'
  export default defineComponent({
    name: "LayoutFooter",
    setup() {
       const { t, locale } = useI18n();
        const router = useRouter();
        const path = router.currentRoute.value.path;
       const getters=useGetters(['language','adlistFooter'])
       const width=ref(document.body.clientWidth);
      var _hmt = _hmt || [];
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?29d0bb373c9216cf6f9e9abca770439f";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
      window.onresize = () => {
        return (() => {
          width.value=document.body.clientWidth;
        })();
      };
      return {
        t,
        path,
        getters,
        width
      };
    },
  });
</script>
<style lang="scss" scoped>
.banner-box{
            width: 100%;
            text-align: center;
            img{
                width: 100%;
                height: 100%;
                // object-fit: cover;
            }       
            .el-carousel__item{display: inline-block !important;}   
        }
  .footer-container {
    .item-center {
      width: 100%;
      min-width: 1200px;
      margin: 0 auto;
      align-items: center;
      padding: 0px;

      .hed-footer {
        width: 100%;
        height: 100px;
        background: #00a0c4;
      }

      .con-footer {
        width: 100%;
        color: #fff;
        background: #222222;

        .el-row {
          width: 1200px;
          margin: 0 auto;
          padding-top: 38px;
          margin-bottom: 48px;

          .link {

            a {
              color: #fff;
              text-decoration: none;
              line-height: 22px;
              font-size: 16px;
            }
          }

          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
          }
        }

        .fot {
          padding: 18px 0;

          span {
            color: rgba(255, 255, 255, 0.81);
            font-size: 14px;
            height: 40px;
            line-height: 20px;
          }
        }

        .wx_code {
          cursor: pointer;
        }

        .wx_code:hover {
          .wx_codeimg {
            display: block !important;
          }
        }
      }
    }
  }

  @media screen and (min-width: 375px) and (max-width: 750px) {
    :deep(.banner-box){
      .el-carousel__container{height: 140px !important;}
    }
    .item-center{min-width: 100% !important;}
    .con-footer {
      width: 100%;
      height: auto !important;

      .el-row {
        width: 97% !important;
        padding-top: 20px !important;
        margin-bottom: 20px !important;
        .el-col-8 {
          max-width: 100%;
          flex: 100%;
          width: 100%;
          height: 65px;
          margin-bottom: 25px;
          img:nth-child(1) {
            width: 30%;
            height: 91%;
            object-fit: contain;
          }

          img:nth-child(2) {
            padding-bottom: 16px;
            margin-left: 20px;
            width: auto !important;
          }
        }

        .el-col-16 {
          max-width: 100%;
          flex: 100%;
          padding-left: 0px !important;
         .email{margin-bottom: 8px !important;}
          .link {
            line-height: 30px;
          }
        }
      }

      .fot {
        padding-bottom: 20px;

        span {
          display: block;
          height: 16px !important;
          line-height: 11px !important;
        }
        span:nth-child(1){margin-bottom: 10px;}
        .n{display: none;}

        img {
          bottom: 0px;
          left: 22%;
        }
      }
    }
  }
</style>